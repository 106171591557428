<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane
        key="1"
        :tab="
          lang == 'uz'
            ? 'Ro\'yxatdan o\'tkazilgan bemorlar'
            : 'Зарегистрированные пациенты'
        "
      >
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardAuthorTable
              :data="table1Data"
              :columns="lang == 'uz' ? table1Columns : table1ColumnsRu"
              @filterGender="filterGender"
              @refreshData="refreshDataPatient"
            ></CardAuthorTable>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        :tab="
          lang == 'uz'
            ? 'Laboratoriyada tekshirilgan bemorlar'
            : 'Пациенты, прошедшие лабораторное тестирование'
        "
        force-render
      >
        <CardLaboratoryCheckedTable
          :data="table2Data"
          :columns="lang == 'uz' ? table2Columns : table2ColumnsRu"
          @refreshData="refreshDataLaboratory"
        ></CardLaboratoryCheckedTable>
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="
          lang == 'uz'
            ? 'Shifokor tashxis qo\'ygan bemorlar'
            : 'Пациенты, диагностированные врачом'
        "
        force-render
      >
        <CardDoctorCheckedTable
          :data="table3Data"
          :columns="lang == 'uz' ? table3Columns : table3ColumnsRu"
          @refreshData="refreshDataDoctor"
        ></CardDoctorCheckedTable>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import axios from "../axios.js";
// "Authors" table component.
import CardAuthorTable from "../components/Users/CardAuthorTable.vue";
import CardLaboratoryCheckedTable from "../components/Users/CardLaboratoryCheckedTable.vue";
import CardDoctorCheckedTable from "../components/Users/CardDoctorCheckedTable.vue";
const table1Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "middleName",
  },
  {
    title: "JINSI",
    dataIndex: "gender",
    // class: 'text-primary',
    scopedSlots: { customRender: "sex_color" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "birthDate",
    class: "text-muted",
    width: 160,
  },
  {
    title: "MANZILI",
    dataIndex: "address",
    class: "text-muted",
  },
  {
    title: "YARATILGAN SANASI",
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 150,
  },
  {
    title: "TAHRIRLASH / O'CHIRISH",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
const table1ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "gender",
    // class: 'text-primary',
    scopedSlots: { customRender: "sex_color" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "birthDate",
    class: "text-muted",
    width: 160,
  },
  {
    title: "АДРЕС",
    dataIndex: "address",
    class: "text-muted",
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 150,
  },
  {
    title: "РЕДАКТИРОВАНИЕ / УДАЛЕНИЕ",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
const table2Columns = [
  // {
  //   title: "RASMI",
  //   dataIndex: "avatar",
  //   scopedSlots: { customRender: "avatar" },
  // },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 145,
  },
  {
    title: "YARATILGAN SANASI",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 145,
  },
  {
    title: "KOMPYUTER TASHXISI",
    dataIndex: "calculatedDisease",
    scopedSlots: { customRender: "calculatedDisease" },
    class: "text-muted",
  },
  {
    title: "TAHRIRLASH / O'CHIRISH",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
const table2ColumnsRu = [
  // {
  //   title: "ФОТО",
  //   dataIndex: "avatar",
  //   scopedSlots: { customRender: "avatar" },
  // },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 145,
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 145,
  },
  {
    title: "КОМПЬЮТЕРНАЯ ДИАГНОСТИКА",
    dataIndex: "calculatedDisease",
    scopedSlots: { customRender: "calculatedDisease" },
    class: "text-muted",
  },
  {
    title: "РЕДАКТИРОВАНИЕ / УДАЛЕНИЕ",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
const table3Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "YARATILGAN SANASI",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "SHIFOKOR TASHXISI",
    dataIndex: "confirmedDisease",
    scopedSlots: { customRender: "confirmedDisease" },
    class: "text-primary",
  },
  {
    title: "TAHRIRLASH / O'CHIRISH",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
const table3ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДИАГНОЗ ВРАЧА",
    dataIndex: "confirmedDisease",
    scopedSlots: { customRender: "confirmedDisease" },
    class: "text-primary",
  },
  {
    title: "РЕДАКТИРОВАНИЕ / УДАЛЕНИЕ",
    scopedSlots: { customRender: "editBtn" },
    width: 200,
  },
];
export default {
  components: {
    CardAuthorTable,
    CardLaboratoryCheckedTable,
    CardDoctorCheckedTable,
  },
  data() {
    return {
      lang: null,
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],

      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,
      table1ColumnsRu: table1ColumnsRu,
      // Associating "Projects" table data with its corresponding property.
      table2Data: [],

      // Associating "Projects" table columns with its corresponding property.
      table2Columns: table2Columns,
      table2ColumnsRu: table2ColumnsRu,
      // Associating "Projects" table data with its corresponding property.
      table3Data: [],

      // Associating "Projects" table columns with its corresponding property.
      table3Columns: table3Columns,
      table3ColumnsRu: table3ColumnsRu,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios.get("/api/patients?size=700&sort=createdAt,desc").then((res) => {
      console.log(res);
      this.table1Data = res.data._embedded.patients;
    });
    axios
      .get("/api/analysis/search?size=700&sort=createdAt,desc")
      .then((res) => {
        console.log(res);
        let data = res.data.content;
        this.table2Data = data.filter((item) => item.laboratoryId != null);
      });
    axios
      .get("/api/analysis/search?size=700&sort=createdAt,desc")
      .then((res) => {
        console.log(res);
        let data = res.data.content;
        this.table3Data = data.filter(
          (item) => item.doctorId != null && item.laboratoryId
        );
      });
  },
  methods: {
    callback() {
      // console.log("CALLBACk");
    },
    refreshDataPatient() {
      axios.get("/api/patients?size=700&sort=createdAt,desc").then((res) => {
        this.table1Data = res.data._embedded.patients;
      });
    },
    refreshDataLaboratory() {
      axios.get("/api/analysis?size=700&sort=createdAt,desc").then((res) => {
        let data = res.data.content;
        this.table2Data = data.filter((item) => item.laboratoryId != null);
      });
    },
    refreshDataDoctor() {
      axios.get("/api/analysis?size=700&sort=createdAt,desc").then((res) => {
        let data = res.data.content;
        this.table3Data = data.filter(
          (item) => item.doctorId != null && item.laboratoryId
        );
      });
    },
    filterGender(val) {
      axios.get("/api/patients?size=700&sort=createdAt,desc").then((res) => {
        if (val == 0) {
          this.table1Data = res.data._embedded.patients;
        } else {
          this.table1Data = res.data._embedded.patients.filter(
            (item) => item.gender == val
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>