<template>
  <!-- Authors Table Card -->
  <div style="position: relative">
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: 25 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="avatar" slot-scope="avatar">
          <div class="table-avatar-info">
            <a-avatar shape="square" src="images/patient.png" />
          </div>
        </template>

        <template slot="firstName" slot-scope="firstName">
          <div class="author-info">
            <h6 class="m-0">{{ firstName }}</h6>
          </div>
        </template>

        <template slot="lastName" slot-scope="lastName">
          <div class="author-info">
            <h6 class="m-0">{{ lastName }}</h6>
          </div>
        </template>

        <template slot="middleName" slot-scope="middleName">
          <div class="author-info">
            <h6 class="m-0">{{ middleName }}</h6>
          </div>
        </template>

        <template slot="gender" slot-scope="gender">
          <div class="author-info">
            <h6
              class="m-0"
              :style="gender == 1 ? 'color:#1890FF;' : 'color:orange;'"
            >
              {{ gender == 1 ? "ERKAK" : "AYOL" }}
            </h6>
          </div>
        </template>

        <template slot="birthDate" slot-scope="birthDate">
          <div class="author-info">
            <h6 class="m-0">{{ formatDate(birthDate) }}</h6>
          </div>
        </template>

        <template slot="createdAt" slot-scope="createdAt">
          <div class="author-info">
            <h6 class="m-0">{{ formatDate(createdAt) }}</h6>
          </div>
        </template>

        <template slot="confirmedDisease" slot-scope="confirmedDisease">
          <div class="author-info">
            <h6 class="m-0" style="font-weight: 700 !important; color: #1890ff">
              {{ confirmedDisease }}
            </h6>
          </div>
        </template>

        <template slot="editBtn" slot-scope="row">
          <div
            class="author-info"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <a-icon
              style="color: #1890ff; cursor: pointer; font-size: 19px"
              :data-id="row.key"
              @click="openPatientPage(row)"
              type="eye"
              theme="outlined"
            />
            <a-icon
              :data-id="row.key"
              @click="getDataEdit(row)"
              style="color: #28a745; cursor: pointer; font-size: 19px"
              type="edit"
              theme="outlined"
            />
            <a-icon
              style="color: #ff4d4f; cursor: pointer; font-size: 19px"
              :data-id="row.key"
              @click="openDeleteModal(row)"
              type="delete"
              theme="outlined"
            />
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="editVisible"
      title="Bemorning tashxis ma'lumotlarini tahrirlash"
      @ok="handleEdit"
    >
      <div class="input_header">Shifokor tashxisi :</div>
      <div class="input_field">
        <span><a-icon type="database" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="tashxis"
          placeholder="Shifokor tashxisi"
          v-model="confirmedDisease"
          required
        />
      </div>
      <div class="input_header">Shifokor tavsiyasi :</div>
      <div class="input_field">
        <textarea
          class="textareaInput"
          type="text"
          name="prescription"
          placeholder="Shifokor tavsiyasi"
          v-model="prescription"
          rows="10"
          required
        >
        </textarea>
      </div>
    </a-modal>
    <a-modal v-model="deleteVisible" @ok="handleDelete">
      <p style="font-weight: 700; font-size: 16px; color: red">
        Bemorning tashxis natijalarini o'chirishni xohlaysizmi ?
      </p>
    </a-modal>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  // components: {
  //   RegisterPatient,
  // },
  data() {
    return {
      thal: null,
      authorsHeaderBtns: "all",
      visible: false,
      editVisible: false,
      deleteVisible: false,
      analysis_id: null,
      patient_id: null,
      confirmedDisease: null,
      prescription: "",
      main_url: "https://api.cardiodiag.uz/api/analysis/",
    };
  },
  methods: {
    openPatientPage(val) {
      router.replace("/analysis_patient_data?analysis_id=" + val.id);
    },
    formatDate(val) {
      let dateJoin = "";
      for (let t = 0; t < 3; t++) {
        if (val[t].toString().length == 1) {
          dateJoin = dateJoin + "0" + val[t];
        } else {
          dateJoin = dateJoin + val[t];
        }
        if (t != 2) {
          dateJoin = dateJoin + "-";
        }
      }
      return dateJoin;
    },
    getDataEdit(obj) {
      this.analysis_id = obj.id;
      (this.confirmedDisease = obj.confirmedDisease),
        (this.prescription = obj.prescription),
        (this.editVisible = true);
    },
    handleEdit() {
      axios
        .patch(this.main_url + this.analysis_id, {
          confirmedDisease: this.confirmedDisease,
          prescription: this.prescription,
        })
        .then(() => {
          this.editVisible = false;
          this.$emit("refreshData");
          this.$toast.success("Bemor ma'lumotlari tahrirlandi !", {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        })
        .catch(() => {
          this.$toast.error(
            "Ma'lumotlarni jo'natishda xatolik yuzaga keldi. Iltimos qayta urinib ko'ring !",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
        });
    },
    openDeleteModal(obj) {
      this.deleteVisible = true;
      this.analysis_id = obj.id;
    },
    handleDelete() {
      axios
        .patch(this.main_url + this.analysis_id, {
          confirmedDisease: null,
          prescription: "1. \n\n2. \n\n3. \n\n4. \n\n5. ",
          doctor: null,
          doctorId: null,
        })
        .then(() => {
          this.$toast.success("Bemor ma'lumotlari o'chirildi !", {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.deleteVisible = false;
          this.$emit("refreshData");
        })
        .catch(() => {
          this.$toast.error(
            "Kasallik varaqasi mavjud bemorni o'chirish mumkin emas !",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;
.btn-watch {
  color: $grey;
  border: 1px solid $grey;
  padding: 10px 20px !important;
}
.btn-watch:hover {
  background-color: $grey;
  color: #fff;
  transition: 0.2s ease;
}
.btn-edit {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 10px 20px !important;
}
.btn-edit:hover {
  background-color: #28a745;
  color: #fff;
  transition: 0.2s ease;
}
.btn-delete {
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  padding: 10px 20px !important;
}
.btn-delete:hover {
  background-color: #ff4d4f;
  color: #fff;
  transition: 0.2s ease;
}
.input_header {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
}
.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}
.input_field {
  position: relative;
  margin-bottom: 20px;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
  > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid $grey;
    text-align: center;
    width: 30px;
    > i {
      padding-top: 10px;
    }
  }
}
.textarea_field {
  > span {
    > i {
      padding-top: 10px;
    }
  }
}
input {
  &[type="text"],
  &[type="date"],
  &[type="email"],
  &[type="password"] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid $grey;
    box-sizing: border-box;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &[type="text"]:hover,
  &[type="email"]:hover,
  &[type="password"]:hover {
    background: #fafafa;
  }
  &[type="text"]:focus,
  &[type="email"]:focus,
  &[type="date"]:focus {
    -webkit-box-shadow: 0 0 2px 1px #1890ff;
    -moz-box-shadow: 0 0 2px 1px #1890ff;
    box-shadow: 0 0 2px 1px #1890ff;
    border: 1px solid $yellow;
    background: #fafafa;
  }
  &[type="submit"] {
    background: $yellow;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: darken($yellow, 7%);
    }
    &:focus {
      background: darken($yellow, 7%);
    }
  }
  &[type="checkbox"],
  &[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: black;
    border: 1px solid $grey;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}
.textareaInput {
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #1890ff;
  outline: none;
}
.textareaInput:hover {
  background: #fafafa;
}
.textareaInput:focus {
  -webkit-box-shadow: 0 0 2px 1px #1890ff;
  -moz-box-shadow: 0 0 2px 1px #1890ff;
  box-shadow: 0 0 2px 1px #1890ff;
  border: 1px solid $yellow;
  background: #fafafa;
}
</style>