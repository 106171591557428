<template>
  <!-- Authors Table Card -->
  <div style="position: relative">
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">
              {{
                lang == "uz"
                  ? "Ro'yxatga olingan bemorlar"
                  : "Зарегистрированные пациенты"
              }}
            </h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-radio-group
              v-model="filterGenderValue"
              size="small"
              style="margin-right: 25px"
            >
              <a-radio-button value="0" @click="filterGender(0)">{{
                lang == "uz" ? "HAMMASI" : "ВСЕ"
              }}</a-radio-button>
              <a-radio-button value="1" @click="filterGender(1)">{{
                lang == "uz" ? "ERKAK" : "МУЖЧИНА"
              }}</a-radio-button>
              <a-radio-button value="2" @click="filterGender(2)">{{
                lang == "uz" ? "AYOL" : "ЖЕНЩИНА"
              }}</a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: 25 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <!-- <template slot="photo" slot-scope="photo">
				<div class="table-avatar-info">
					<a-avatar shape="square" :src="photo" />
				</div>
			</template> -->

        <template slot="avatar" slot-scope="avatar">
          <div class="table-avatar-info">
            <a-avatar shape="square" src="images/patient.png" />
          </div>
        </template>

        <template slot="firstName" slot-scope="firstName">
          <div class="author-info">
            <h6 class="m-0">{{ firstName }}</h6>
          </div>
        </template>

        <template slot="lastName" slot-scope="lastName">
          <div class="author-info">
            <h6 class="m-0">{{ lastName }}</h6>
          </div>
        </template>

        <template slot="middleName" slot-scope="middleName">
          <div class="author-info">
            <h6 class="m-0">{{ middleName }}</h6>
          </div>
        </template>

        <template slot="sex_color" slot-scope="gender">
          <div class="author-info">
            <h6
              class="m-0"
              :style="gender == 1 ? 'color:#1890FF;' : 'color:orange;'"
            >
              {{ gender == 1 ? "ERKAK" : "AYOL" }}
            </h6>
          </div>
        </template>

        <template slot="birthDate" slot-scope="birthDate">
          <div class="author-info">
            <h6 class="m-0">{{ birthDate }}</h6>
          </div>
        </template>

        <template slot="address" slot-scope="address">
          <div class="author-info">
            <h6 class="m-0">{{ address }}</h6>
          </div>
        </template>

        <template slot="createdAt" slot-scope="createdAt">
          <div class="author-info">
            <h6 class="m-0">{{ createdAt.substring(0, 10) }}</h6>
          </div>
        </template>

        <template slot="editBtn" slot-scope="row">
          <div
            class="author-info"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <a-icon
              style="color: #1890ff; cursor: pointer; font-size: 19px"
              :data-id="row.key"
              @click="getDataEdit(row)"
              type="eye"
              theme="outlined"
            />
            <a-icon
              :data-id="row.key"
              @click="getDataEdit(row)"
              style="color: #28a745; cursor: pointer; font-size: 19px"
              type="edit"
              theme="outlined"
            />
            <a-icon
              style="color: #ff4d4f; cursor: pointer; font-size: 19px"
              :data-id="row.key"
              @click="openDeleteModal(row)"
              type="delete"
              theme="outlined"
            />
            <!-- <a-button
              type="link"
              :data-id="row.key"
              @click="getDataEdit(row)"
              class="btn-edit"
            >
              Tahrirlash
            </a-button>
            <a-button
              type="link"
              :data-id="row.key"
              @click="openDeleteModal(row)"
              class="btn-delete"
            >
              O'chirish
            </a-button> -->
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="editVisible"
      :title="
        lang == 'uz'
          ? 'Bemor ma\'lumotlarini tahrirlash'
          : 'Редактировать информацию о пациенте'
      "
      @ok="handleEdit"
    >
      <div class="input_header">{{ lang == "uz" ? "ISMI :" : "ИМЯ :" }}</div>
      <div class="input_field">
        <span><a-icon type="user" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="ismi"
          :placeholder="lang == 'uz' ? 'Ismi' : 'Имя'"
          v-model="first_name"
          required
        />
      </div>
      <div class="input_header">
        {{ lang == "uz" ? "FAMILIYASI :" : "ФАМИЛИЯ :" }}
      </div>
      <div class="input_field">
        <span><a-icon type="user" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="familiyasi"
          :placeholder="lang == 'uz' ? 'Familiyasi' : 'Фамилия'"
          v-model="last_name"
          required
        />
      </div>
      <div class="input_header">
        {{ lang == "uz" ? "OTASINING ISMI :" : "ОТЧЕСТВО :" }}
      </div>
      <div class="input_field">
        <span><a-icon type="user" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="sharifi"
          :placeholder="lang == 'uz' ? 'Sharifi' : 'Отчество'"
          v-model="middle_name"
          required
        />
      </div>
      <div class="input_header">{{ lang == "uz" ? "JINSI :" : "ПОЛ :" }}</div>
      <div class="input_field select_option">
        <select v-model="gender">
          <option value="null">
            {{ lang == "uz" ? "Jinsini tanlang" : "Выберите пол" }}
          </option>
          <option value="1">{{ lang == "uz" ? "Erkak" : "Мужчина" }}</option>
          <option value="2">{{ lang == "uz" ? "Ayol" : "Женщина" }}</option>
        </select>
        <div class="select_arrow"></div>
      </div>
      <div class="input_header">
        {{ lang == "uz" ? "TUG'ILGAN SANASI :" : "ДАТА РОЖДЕНИЯ :" }}
      </div>
      <div class="input_field">
        <span><a-icon type="calendar" theme="outlined" /></span>
        <input
          class="inputField"
          type="date"
          name="sana"
          v-model="birth_date"
          required
        />
      </div>
      <div class="input_header">{{ lang == "uz" ? "PINFL :" : "ПИНФЛ :" }}</div>
      <div class="input_field">
        <span><a-icon type="database" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="pinfl"
          maxlength="14"
          @input="isNumber()"
          :placeholder="lang == 'uz' ? 'PINFL' : 'ПИНФЛ'"
          v-model="pinfl"
          required
        />
      </div>
      <div class="input_header">
        {{ lang == "uz" ? "TELEFON RAQAMI :" : "НОМЕР ТЕЛЕФОНА :" }}
      </div>
      <div class="input_field">
        <span><a-icon type="phone" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="telefon"
          :placeholder="lang == 'uz' ? 'Telefon raqami' : 'Номер телефона'"
          maxlength="13"
          v-model="phone_number"
          required
        />
      </div>
      <div class="input_header">
        {{ lang == "uz" ? "MANZILI :" : "АДРЕС :" }}
      </div>
      <div class="input_field">
        <span><a-icon type="home" theme="outlined" /></span>
        <input
          class="inputField"
          type="text"
          name="manzili"
          :placeholder="lang == 'uz' ? 'Manzili' : 'Адрес'"
          v-model="address"
          required
        />
      </div>
    </a-modal>
    <a-modal v-model="deleteVisible" @ok="handleDelete">
      <br />
      <a-alert
        :message="
          lang == 'uz'
            ? 'Agar bemorni o\'chirsangiz uning barcha kasallik tarixlari ham o\'chiriladi !'
            : 'Если вы удалите пациента, вся его история болезни также будет удалена!'
        "
        type="error"
        show-icon
      /><br />
      <p style="font-weight: 700; font-size: 16px; color: red">
        {{
          lang == "uz"
            ? "Bemorni o'chirishni xohlaysizmi ?"
            : "Вы хотите удалить пациента?"
        }}
      </p>
    </a-modal>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lang: "uz",
      filterGenderValue: "0",
      visible: false,
      editVisible: false,
      deleteVisible: false,
      first_name: "",
      last_name: "",
      middle_name: "",
      birth_date: null,
      address: "",
      phone_number: "+998",
      gender: null,
      pinfl: "",
      patient_link: "",
      patientId: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
  methods: {
    filterGender(val) {
      this.$emit("filterGender", val);
    },
    getDataEdit(obj) {
      this.patient_link = obj._links.self.href;
      this.first_name = obj.firstName;
      this.last_name = obj.lastName;
      this.middle_name = obj.middleName;
      this.phone_number = obj.phoneNumber;
      this.pinfl = obj.pnfl;
      this.gender = obj.gender;
      this.address = obj.address;
      this.birth_date = obj.birthDate;
      this.editVisible = true;
    },
    handleEdit() {
      axios
        .patch(this.patient_link, {
          firstName: this.first_name,
          lastName: this.last_name,
          middleName: this.middle_name,
          phoneNumber: this.phone_number,
          pnfl: this.pinfl,
          gender: this.gender,
          address: this.address,
          birthDate: this.birth_date,
        })
        .then(() => {
          this.editVisible = false;
          this.$emit("refreshData");
          this.$toast.success(
            this.lang == "uz"
              ? "Bemor ma'lumotlari tahrirlandi !"
              : "Информация о пациенте отредактирована!",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
        })
        .catch(() => {
          this.$toast.error(
            this.lang == "uz"
              ? "Ma'lumotlarni jo'natishda xatolik yuzaga keldi. Iltimos qayta urinib ko'ring !"
              : "Произошла ошибка при отправке данных. Пожалуйста, попробуйте еще раз !",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
        });
    },
    openDeleteModal(obj) {
      this.deleteVisible = true;
      this.patient_link = obj._links.self.href;
      this.patientId = this.patient_link.substring(
        this.patient_link.lastIndexOf("/") + 1,
        this.patient_link.length
      );
    },
    handleDelete() {
      axios
        .get("/api/analysis/search?size=700&sort=createdAt,desc")
        .then((res) => {
          res.data.content.forEach((t) => {
            if (t.patientId == this.patientId) {
              axios.delete("/api/analysis/" + t.id);
            }
          });
        });
      setTimeout(() => {
        axios
          .delete(this.patient_link)
          .then(() => {
            this.$toast.success(
              this.lang == "uz"
                ? "Bemor ma'lumotlari o'chirildi !"
                : "Данные пациента удалены !",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
            this.deleteVisible = false;
            this.$emit("refreshData");
          })
          .catch(() => {
            this.$toast.error(
              this.lang == "uz"
                ? "Kasallik varaqasi mavjud bemorni o'chirish mumkin emas !"
                : "Невозможно удалить пациента, у которого открыт больничный лист !",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;
.btn-edit {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 10px 20px !important;
}
.btn-edit:hover {
  background-color: #28a745;
  color: #fff;
  transition: 0.2s ease;
}
.btn-delete {
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  padding: 10px 20px !important;
  margin-left: 10px;
}
.btn-delete:hover {
  background-color: #ff4d4f;
  color: #fff;
  transition: 0.2s ease;
}
.input_header {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
}
.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}
.input_field {
  position: relative;
  margin-bottom: 20px;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
  > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid $grey;
    text-align: center;
    width: 30px;
    > i {
      padding-top: 10px;
    }
  }
}
.textarea_field {
  > span {
    > i {
      padding-top: 10px;
    }
  }
}
input {
  &[type="text"],
  &[type="date"],
  &[type="email"],
  &[type="password"] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid $grey;
    box-sizing: border-box;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &[type="text"]:hover,
  &[type="email"]:hover,
  &[type="password"]:hover {
    background: #fafafa;
  }
  &[type="text"]:focus,
  &[type="email"]:focus,
  &[type="date"]:focus {
    -webkit-box-shadow: 0 0 2px 1px #1890ff;
    -moz-box-shadow: 0 0 2px 1px #1890ff;
    box-shadow: 0 0 2px 1px #1890ff;
    border: 1px solid $yellow;
    background: #fafafa;
  }
  &[type="submit"] {
    background: $yellow;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: darken($yellow, 7%);
    }
    &:focus {
      background: darken($yellow, 7%);
    }
  }
  &[type="checkbox"],
  &[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: black;
    border: 1px solid $grey;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}
</style>